import {
  Container,
  ContentAbout,
  ContentUpdate,
  DateUpdate,
  DescriptionAbout,
  ItemImage,
  ListImage,
  TextData,
  TitleAbout,
  TitleUpdate,
  WrapperData,
  WrapperTitle,
} from './ContentInfo.styled'
import img1 from './assets/img-1.png'
import img2 from './assets/img-2.png'
import img3 from './assets/img-3.png'
import img4 from './assets/img-4.png'
import { ArrowDown, ArrowForward } from '@shared/assets'
import { useState } from 'react'

export const ContentInfo: React.FC = () => {
  const [isDescription, setIsDescription] = useState(false)

  return (
    <Container>
      <ListImage>
        <ItemImage src={img1} alt={'img-1'} />
        <ItemImage src={img2} alt={'img-2'} />
        <ItemImage src={img3} alt={'img-3'} />
        <ItemImage src={img4} alt={'img-4'} />
      </ListImage>
      <ContentAbout>
        <WrapperTitle>
          <TitleAbout>About this game</TitleAbout>
          {isDescription ? (
            <ArrowDown onClick={() => setIsDescription(prev => !prev)} />
          ) : (
            <ArrowForward onClick={() => setIsDescription(prev => !prev)} />
          )}
        </WrapperTitle>
        <DescriptionAbout $isDescription={isDescription}>
          😱 Miss no chance - ₹100,000,000
          <br />
          🐺 Yo Yo Yo is in touch! Download the app and get ₹2500 directly on your card! 💸
          <br />
          <br />
          🤑 My Developers team has created this casino, where I give millions of dollars to anyone
          who wishes!
          <br />
          Especially for you, I have compiled the best slots and games from all casinos in the
          world:
          <br />
          🍬 Aviator
          <br />
          📖 Book of RA
          <br />
          ⚡️ Gates of Olympus
          <br />
          🔮 Plinko
          <br />
          🐶 Dog house
          <br />
          🍭 sugar rush
          <br />
          🐺 Wolf gold and many others!
          <br />
          Casino is an exciting mobile application that offers a unique temptation of the casino
          world. Combining casino extravagance and snapping, this app is the perfect place for all
          gambling lovers.
          <br />
          When you register with the app, you will receive a special bonus that will allow you to
          start your casino journey on a bright note. Plus, you'll be able to spin the reels for
          free and increase your chances of winning in exciting slot games.
          <br />
          To continue the entertainment, deposit bonuses await you, allowing you to increase your
          winnings and enjoy even more. We also value our players, so you have a unique betting
          limit available.
          <br />
          So, are you ready to immerse yourself in a world of excitement and opportunity? Download
          casino and start an exciting casino journey full of bonuses, free spins and unforgettable
          wins. Bright emotions and unique opportunities await you only in our application!
        </DescriptionAbout>
        <ContentUpdate>
          <TitleUpdate>Updated on</TitleUpdate>
          <DateUpdate>March 2, 2024</DateUpdate>
        </ContentUpdate>
        <ContentUpdate>
          <WrapperTitle>
            <TitleAbout>Data safety</TitleAbout>
            <ArrowForward />
          </WrapperTitle>
          <DescriptionAbout>
            In this section, developers can specify how applications collect and use data.
          </DescriptionAbout>
          <WrapperData>
            <TextData>No information.</TextData>
          </WrapperData>
        </ContentUpdate>
      </ContentAbout>
    </Container>
  )
}
